import Service from '../Service';

const resource = "Turnreasonstop/"

export default {

    save(tps, requestID) {
        return Service.post(resource + 'save', tps, {
            params: { requestID }
        });
    },
    pagination(tps, requestID) {
        return Service.post(resource + 'pagination', tps, {
            params: { requestID }
        });
    },
    list(TypeArea, TypeShip, requestID) {
        return Service.post(resource + 'list', {}, {
            params: { TypeArea, TypeShip, requestID }
        });
    },
}