import Service from '../Service';

const resource = "turnpresentation/"

export default {

    save(trp, requestID) {
        return Service.post(resource + 'save', trp, {
            params: { requestID }
        });
    },

    list(TrnID,requestID) {
        return Service.post(resource + 'list', {}, {
            params: {requestID:requestID ,TrnID:TrnID, }
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },

}