import Service from '../Service';

const resource = "turnstop/"

export default {

    save(tps, requestID) {
        return Service.post(resource + 'save', tps, {
            params: { requestID }
        });
    },
    pagination(tps, requestID) {
        return Service.post(resource + 'pagination', tps, {
            params: { requestID }
        });
    },
}