<template>
	<div>
		<s-toolbar
			dark
			:color="'#8e8f91'"
			v-if="turn"
			:label="
				'Turno: ' +
				this.$fun.formatDateTimeView(turn.TnrDateBegin) +
				' - Actual'
			"
		></s-toolbar>

		<s-toolbar v-else label="No Existe Turno"> </s-toolbar>
		<v-card class="d-flex justify-center mb-6 pl-20 pr-20">
			<v-container>
				<v-row class="mb-3 pb-0" justify="center">
					<v-col lg="4">
						<s-select-definition
							label="Area"
							:def="1281"
							:dgr="39"
							v-model="TypeArea"
						/>
					</v-col>
					<!---->
					<v-col
						cols="12"
						lg="2"
						md="4"
						sm="12"
						class="mt-3 pb-0"
						v-if="turn"
					>
						<v-btn
							block
							rounded
							color="primary"
							@click="startTurn()">
							Nuevo Tiempo
						</v-btn>
					</v-col>

					<!-- :disabled="turn.PrfTurnPresentationCE != null" -->

					<!-- <v-col cols="12" lg="2" class="mt-3 pb-0" v-if="turn">
						<v-btn
							rounded
							v-if="turn.PrfTurnPresentationCE"
							block
							color="error"
							@click="endTurn()"
						>
							<i
								style="font-size: 24px; margin-right: 6px"
								class="far fa-stop-circle"
							></i>
							Terminar
						</v-btn>
					</v-col> -->
				</v-row>
				<v-row> <v-divider></v-divider></v-row>
				<v-row style="mt-3" justify="center">
					<v-col cols="12" lg="4" class="pb-0">
						<s-supplier
							label="Cliente"
							v-model="client"
						></s-supplier>
					</v-col>
					<v-col lg="2">
						<s-select-definition
							label="Tipo Presentación"
							:def="1349"
							v-model="TypePresentationPacking"
						/>
					</v-col>
					<v-col cols="12" lg="2" md="3" sm="12">
						<s-select-definition
							:def="1274"
							label="Presentación"
							return-object
							v-model="objPresentation"
						/>
					</v-col>

					<v-col cols="6" md="3" lg="2">
						<s-select-definition
							:def="1173"
							label="Cultivo"
							v-model="TypeCultive"
						/>
					</v-col>
					<v-col>
						<s-select-definition
							:def="1266"
							label="Calibres"
							v-model="TypeCaliber">

						</s-select-definition>
					</v-col>
				</v-row>
				<v-row style="mt-3" justify="center">
					<v-col cols="4">
						<s-select-definition
							label="Marca de caja"
							v-model="TypeBrandBox"
							:def="1427">
							
						</s-select-definition>
					</v-col>

					<v-col cols="4" md="3" lg="2">
						<s-select-definition
							:def="1172"
							label="Tipo Cultivo"
							v-model="TypeCrop"
						/>
					</v-col>

					<v-col cols="4" md="3" lg="2">
						<s-select-variety
							:cultiveID="TypeCultive"
							label="Variedad"
							full
							v-model="VrtID"
						/>
					</v-col>
				</v-row>
			</v-container>
		</v-card>

		<v-row v-if="turn">
			<v-col class="pt-0">
				<s-crud
					title="Tiempos Productivos"
					:filter="filter"
					:config="configTurnPresentation"
					ref="gridTurnPresentation"
					@rowSelected="rowSelected($event)"
					height="auto"
				>
					<template v-slot:TrpStopOption="{row}">
						<v-btn
							rounded
							v-if="row.TrpStatus == 1"
							x-small
							color="error"
							@click="endTurn(row)"
						>
							<i
								style=""
								class="far fa-stop-circle"
							></i>
							Terminar
						</v-btn>
					</template>
				</s-crud>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import SSelectDefinition from "@/components/Utils/SSelectDefinition.vue";
	import _sPrfTurnPresentation from "../../../services/FreshProduction/PrfTurnPresentationService";
	import _sTurn from "@/services/FreshProduction/PrfTurnService";
	import _sPrfTurnPresentationStop from "@/services/FreshProduction/PrfTurnStopService";
	import _sPrfTurnReasonStop from "@/services/FreshProduction/PrfTurnStopReasonService";
	import _sPrfTurnStop from "@/services/FreshProduction/PrfTurnStopService";
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
	import SSupplier from "@/components/Sale/SselectClientSap";
	export default {
		components: {
			SSelectDefinition,
			SSelectVariety,
			SSupplier,
		},

		data() {
			return {
				client: null,
				showAdd: false,
				RstStatus: 1,
				itemsReason: [],
				turn: null,
				TypeArea: 0,
				TpsDateBegin: "",
				TpsDateEnd: "",
				reason: "",
				dialog: false,
				objPresentation: null,
				TrpID: 0,
				start: false,
				end: false,
				//itemsTurnPresentation: [],
				/* headers: [
					{ text: "ID", value: "TrpID" },
					{ text: "Presentación", value: "TypeBoxPresentation" },
				], */
				filter: { TypeArea: 0, TypeShip: 1 },
				filterStop: { TrnID: 0 },
				/* configTurnStop: {
					model: {
						StoID: "ID",
						StoBeginDate: "datetime",
						StoEndDate: "datetime",
					},
					service: _sPrfTurnStop,
					headers: [
						{ text: "ID", value: "StoID" },
						{ text: "Motivo", value: "RstDescription" },
						{ text: "Fecha Inicio", value: "StoBeginDate" },
						{ text: "Fecha Fin", value: "StoEndDate" },
						{ text: "Estado", value: "StoStatusName" },
					],
				}, */


				configTurnPresentation: {
					model: {
						TrpID: "ID",
						TrpDateBegin: "datetime",
						TrpDateEnd: "datetime",
						TrpStopOption: ""
					},
					service: _sPrfTurnPresentation,
					headers: [
						{ text: "ID", value: "TrpID",width: 50, },
						{ text: "Tipo Presentación", value: "TypeTurnPresentationName",width: 50, },
						{ text: "Stop", value: "TrpStopOption",width: 100, },
						{
							text: "Presentación",
							value: "TypeBoxPresentationName",
							width: 600,
						},
						{text: "Calibre", value: "TypeCaliberName", width: 100,},
						{ text: "Fecha Inicio", value: "TrpDateBegin",width: 200, },
						{ text: "Fecha Fin", value: "TrpDateEnd",width: 200, },
						{ text: "Estado", value: "TrpStatusName",width: 100, },
					],
				},

				TypeDestiny: null,
				TypeCultive: null,
				TypeCaliber: null,
				objCultive : null,
				TypeBrandBox: null,
				TypeCrop: null,
				VrtID: null,
				itemView: {},
				TypePresentationPacking: 1,
			};
		},
		watch: {
			showAdd() {
				if (this.showAdd == false) {
					this.turn.TrpName = "";
				}
			},
			TypeArea() {
				//traer turno activo de esta area
				_sTurn.turnexist(this.$fun.getUserID(), this.TypeArea).then((r) => {
					if (r.data.length > 0) {
						this.filterStop.TrnID = r.data[0].TrnID;
						this.filter.TypeArea = r.data[0].TypeArea;
						this.filter.TypeShip = 1
						this.turn = r.data[0];
						_sPrfTurnReasonStop
							.list(this.turn.TypeArea, 1, this.$fun.getUserID())
							.then((r) => {
								this.itemsReason = r.data;
							});
					} else {
						this.turn = null;
					}
				});
			},

			

		},
		methods: {
			rowSelected(item) {
				if (item.length > 0) {
					if (item !== undefined) {
						this.itemView = item[0];
						console.log("item", this.itemView);
					}
				}
			},

			// inputCultivo(item) {
			// 	console.log('Tenemos el tipo de cultivo david', item);
			// 	console.log('Tenemos el valor del cultivo', item.DedValue);
			// 	this.TypeCultive = item.DedValue;
			// 	this.TypeCaliber = parseInt(itme.DedAbbreviation);
			// },


			/* anulateStop(objStop) {
				if (objStop) {
					//CULMINAR PARADA
					objStop.StoEndDate = this.$fun.getUserID();
					this.$fun.alert("Seguro de Reanudar?", "question").then((v) => {
						if (v.value) {
							_sPrfTurnStop
								.save(objStop, this.$fun.getUserID())
								.then((r) => {
									this.turn.PrfTurnStopCE = null;
									this.$fun.alert("Turno reiniciado!", "success");
								});
						}
					});
				} else {
					this.dialog = true;
					this.RstStatus = 1;
				}
			}, */
			/* doubleClick(item) {
				console.log("item ", item);
				this.dialog = true;
			},

			close() {
				this.dialog = false;
			}, */

			hasTurnPresentationActive() {
				if (this.turn)
					_sPrfTurnPresentation
						.list(this.turn.TrnID, this.$fun.getUserID())
						.then((resp) => {
							if (resp.status == 200) {
								let data = resp.data;

								if (data.length != 0) {
									console.log("data ", data);
									this.start = true;
									this.end = false;
									this.TrpID = data[0].TrpID;
								} else {
									this.start = false;
									this.end = true;
								}
								//this.itemsTurnPresentation = resp.data;
							}
						});
			},

			startTurn() {

				console.log(this.TypePresentationPacking);

				if (this.turn == null) {
					this.$fun.alert("No existe Turno de el área", "warning");
					return;
				}

				if (this.client == null) {
					this.$fun.alert("No existe Cliente", "warning");
					return;
				}

				
				if (this.TypePresentationPacking == null) {
					this.$fun.alert("Seleccione tipo presentación", "warning");
					return;
				}

				

				if (this.TypeArea == 2) {
					if (this.TypeCultive == null) {
						this.$fun.alert("Seleccione Cultivo", "warning");
						return;
					}

					if (this.VrtID == null) {
						this.$fun.alert("Seleccione variedad", "warning");
						return;
					}
				} else {
				}
				this.$fun
					.alert("¿Seguro de iniciar tiempo Productivo?", "question")
					.then((val) => {
						if (val.value) {
							let obj = {
								TrnID: this.turn.TrnID,
								TypeBoxPresentation: this.objPresentation
									? this.objPresentation.DedValue
									: null,
								TrpName: this.turn.TrpName,
								TypeArea: this.TypeArea,
								TrpDateBegin: new Date(),
								TrpDateEnd: new Date(),
								TrpStatus: 1,
								SecStatus: 1,
								UsrCreateID: this.$fun.getUserID(),
								SecCreate: new Date(),
								UsrUpdateID: this.$fun.getUserID(),
								SecUpdate: new Date(),
								TypeDestiny: this.TypeDestiny,
								TypeCultive: this.TypeCultive, 
								TypeCaliber :this.TypeCaliber,
								TypeBrandBox: this.TypeBrandBox,
								TypeCrop : this.TypeCrop,
								VrtID: this.VrtID,
								ClnCode: this.client,
								TypePresentationPacking: this.TypePresentationPacking,
							};
							
							//SI NO ES EMPAQUE
							_sPrfTurnPresentation
								.save(obj, this.$fun.getUserID())
								.then((resp) => {
									if (resp.status == 200) {
										this.turn.PrfTurnPresentationCE = resp.data;
										this.$fun.alert(
											"Turno Iniciado Correctamente",
											"success"
										);
										this.showAdd = false;
										this.$refs.gridTurnPresentation.refresh();
										if (this.TypeArea != 2) {
											obj.VrtID = null;
											obj.TypeBoxPresentation = null;
											obj.TypeCultive = null;
											obj.TypeDestiny = null;
										}
									}
								});
						}
					});
			},

			endTurn(item) {
				console.log(item, this.turn);
				this.$fun
					.alert("¿Seguro de finalizar turno?", "question")
					.then((val) => {
						if (val.value) {
							let obj = {
								TrpID: item.TrpID,//this.turn.PrfTurnPresentationCE.TrpID,
								TrnID: item.TrnID,//this.turn.TrnID,
								//TypeBoxPresentation: this.objPresentation.DedValue,
								TrpDateBegin: new Date(),
								TrpDateEnd: new Date(),
								TrpStatus: 1,
								SecStatus: 1,
								UsrCreateID: this.$fun.getUserID(),
								SecCreate: new Date(),
								UsrUpdateID: this.$fun.getUserID(),
								SecUpdate: new Date(),
							};
							_sPrfTurnPresentation
								.save(obj, this.$fun.getUserID())
								.then((resp) => {
									if (resp.status == 200) {
										this.$fun.alert(
											"Turno Cerrado Correctamente",
											"success"
										);
										this.end = true;
										this.start = false;
										this.turn.PrfTurnPresentationCE = null;
										this.$refs.gridTurnPresentation.refresh();
									}
								});
						}
					});
			},
		},

		created() {
			this.hasTurnPresentationActive();
		},
	};
</script>
